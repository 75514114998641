import React, { useEffect, useState } from "react"
import { Container, Row, Col, Button, Progress, Form, Input, Spinner } from "reactstrap"
import { useHistory } from "react-router"
import { useMutation } from "react-query"
import Footer from "../Footer"
import Header from "../Header"
import InvalidationReportApi from "../../api/InvalidationAPI"
import "./landingPage.scss"

const GenerateInvalidation = ({ match }) => {
  const [progressValue, setProgressValue] = useState(0),
    [email, setEmail] = useState(""),
    history = useHistory(),
    {
      params: { ucid },
    } = match,
    { mutate, error, status } = useMutation(InvalidationReportApi.create, {
      onSuccess: () => {
        history.push(`/generate-invalidation/${ucid}?success=1`)
      },
    })

  useEffect(() => {
    if (progressValue >= 100) {
      return
    }

    const interval = setInterval(() => {
      setProgressValue((value) => value + 1)
    }, 50)

    return () => {
      clearInterval(interval)
    }
  }, [progressValue === 100])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const confirmDownload = (event) => {
    event.preventDefault()
    mutate({ email, ucid })
  }

  const onChangeEmail = (event) => setEmail(event.target.value)

  return (
    <div className="d-flex flex-column justify-content-between min-vh-100 text-primary font-avenir">
      <Header />
      <h1 className="text-center font-weight-bolder font-avenir-bold topline pt-2 pt-md-5">Invalidation Report</h1>
      <main className="py-5 mb-5 mt-4">
        <Container className="d-flex justify-content-center">
          <Col md="7">
            {status === "success" ? (
              <p className="lead text-success text-center">
                Thank you! Your report will be sent to your inbox shortly, typically within 10-15 minutes.
              </p>
            ) : progressValue < 100 ? (
              <>
                <p className="lead text-center">We’re processing your request. Please wait...</p>
                <Progress
                  color="success"
                  value={progressValue}
                  max={100}
                  className="invalidation-progress-bar font-weight-bold"
                >
                  {progressValue}%
                </Progress>
              </>
            ) : (
              <>
                <p className="lead text-center">Your invalidation report is being generated. 
                  Enter your email below, and we’ll notify you when it’s ready!</p>
                <p className="lead py-2 text-center font-weight-bold font-avenir-bold">{ucid}</p>
                <Form onSubmit={confirmDownload}>
                  <Row className="text-center">
                    <Col md="8">
                      <Input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email"
                        className="bg-light"
                        value={email}
                        onChange={onChangeEmail}
                        required
                        pattern="^[^@\s]+@[^@\s]+\.[^@\s]+$"
                      />
                      <p className="text-danger mb-0 pt-2">{error && error.message}</p>
                    </Col>
                    <Col md="4" className="pt-4 p-md-0">
                      <Button color="secondary" className="px-5 text-white">
                        {status === "loading" ? <Spinner size="sm" color="light" className="mx-4" /> : "Get it now"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </>
            )}
          </Col>
        </Container>
      </main>
      <Footer home />
    </div>
  )
}

export default GenerateInvalidation
