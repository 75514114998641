import React, { useState } from "react"
import { Col, Container, Row, Card, Form, FormGroup, Label, Input, Button, CardBody, Spinner } from "reactstrap"
import ScrollableAnchor from "react-scrollable-anchor"
import { useHistory } from "react-router"
import Header from "../Header"
import Footer from "../Footer"
import Start15DayFreeTrialButton from "../Start15DayFreeTrialButton"
import extractIcon from "../../images/Funnel Icon.png"
import spotIcon from "../../images/Spot icon.png"
import documentIcon from "../../images/Document icon.png"
import invalidationIcon from "../../images/invalidation.png"
import classificationIcon from "../../images/text category.png"
import greenCheckIcon from "../../images/green-check.png"
import circleLeftLarge from "../../images/circle-left-large.png"
import circleLeftSmall from "../../images/circle-left-small.png"
import circleRight from "../../images/circle-right.png"
import doneForYouService from "../../images/done-for-you-service.png"
import dataCatalogs from "../../images/data-catalogs.png"
import InvalidationReportApi from "../../api/InvalidationAPI"
import { useMutation } from "react-query"
import BuiltInMind from "./builtInMind"
import "./landingPage.scss"

const GreenCheckIcon = ({ className }) => (
  <img src={greenCheckIcon} alt="green check" width="22px" className={className} />
)

const LandingPage = () => {
  const [UCIDforInvalidation, setUCIDforInvalidation] = useState(""),
    history = useHistory(),
    { mutate: dataForInvalidationExists, error: dataExistError, statusData } 
        = useMutation(InvalidationReportApi.fileExists, {
          onSuccess: () => {
            history.push(`/generate-invalidation/${UCIDforInvalidation}`)
          },
        })

  const doesdataForInvalidationExists = async (event) => {
    event.preventDefault()
    dataForInvalidationExists(UCIDforInvalidation)
  }

  const onChangeUCIDforInvalidation = (event) => {
    setUCIDforInvalidation(event.target.value)
  }

  return (
    <div className="font-avenir">
      <Header dark />
      <main>
        <Container className="text-center py-5">
          <ScrollableAnchor id="why-traindex">
            <h2 className="text-primary font-weight-bold font-avenir-bold pt-4">Why Traindex?</h2>
          </ScrollableAnchor>
          <Row className="py-4 lead">
            <Col xs="12" md="6" className="text-center p-4 p-lg-5">
              <div>
                <img src={spotIcon} alt="spot icon" className="w-25" />
              </div>
              <p className="pt-3 w-75 m-auto">
                Search with an understanding of meaning and not just the literal keywords
              </p>
            </Col>
            <Col xs="12" md="6" className="text-center p-4 p-lg-5">
              <div>
                <img src={documentIcon} alt="document icon" className="w-25" />
              </div>
              <p className="pt-3 w-75 m-auto">Synonyms don&apos;t have to be explicitly included</p>
            </Col>
            <Col xs="12" md="6" className="text-center p-4 p-lg-5">
              <div>
                <img src={classificationIcon} alt="classification icon" className="w-25" />
              </div>
              <p className="pt-3 w-75 m-auto">No need to carefully pick the right keywords for the search</p>
            </Col>
            <Col xs="12" md="6" className="text-center p-4 p-lg-5">
              <div>
                <img src={extractIcon} alt="extract icon" className="w-25" />
              </div>
              <p className="pt-3 w-75 m-auto">
                No need to derive page ranking or other indirect information to rank results
              </p>
            </Col>
          </Row>

          <ScrollableAnchor id="invalidation-search">
            <h2 className="text-primary font-weight-bold font-avenir-bold pt-4">Invalidation Search</h2>
          </ScrollableAnchor>
          <p className="text-primary lead">Get a free invalidation search report in your email inbox</p>
          <Row className="py-5 align-items-center">
            <Col xs="12" md="6" className="pb-4 pb-md-0">
              <img
                src={invalidationIcon}
                alt="invalidation report icon"
                className="invalidation-report-icon"
              />
            </Col>
            <Col xs="12" md="6">
              <Card className="shadow-lg border-0">
                <CardBody>
                  <Form onSubmit={doesdataForInvalidationExists}>
                    <FormGroup className="d-flex flex-column align-items-center">
                      <Label
                        for="UCIDforInvalidation" 
                        className="text-primary font-avenir-bold font-weight-bold lead py-3"
                      >
                        Enter your patent UCID
                      </Label>
                      <Input
                        type="text"
                        name="UCIDforInvalidation"
                        id="UCIDforInvalidation"
                        placeholder="Example US-12345678-B1"
                        className="bg-light w-75"
                        value={UCIDforInvalidation}
                        onChange={onChangeUCIDforInvalidation}
                        required
                      />
                      <p className="text-danger mb-0 pt-2">{dataExistError && dataExistError.message}</p>
                    </FormGroup>
                    <Button color="pink my-4 rounded-pill px-4">
                      {statusData === "loading" && <Spinner size="sm" color="light" className="mr-1" />}
                      Get free report
                    </Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <div className="bg-secondary py-2 container-fluid"></div>

        <section className="bg-light">
          <Container className="text-center text-primary py-5">
            <h2 className="font-weight-bold font-avenir-bold pt-4 topline">
              Built on <span className="text-secondary">open</span> technologies
            </h2>
            <p className="lead">Traindex uses battle tested machine learning models</p>
            <div className="d-flex align-items-center justify-content-center flex-wrap pt-4">
              <div className="d-flex align-items-center">
                <GreenCheckIcon />
                <span className="green-check-text pl-2">DOC2VEC</span>
              </div>
              <div className="d-flex align-items-center">
                <GreenCheckIcon className="ml-3 ml-md-5" />
                <span className="green-check-text pl-2">LSA</span>
              </div>
              <div className="d-flex align-items-center">
                <GreenCheckIcon className="ml-3 ml-md-5" />
                <span className="green-check-text pl-2">BERT</span>
              </div>
              <div className="d-flex align-items-center">
                <GreenCheckIcon className="ml-3 ml-md-5" />
                <span className="green-check-text pl-2">GPT</span>
              </div>
            </div>
          </Container>
        </section>

        <ScrollableAnchor id="semantic-search">
          <div>
            <Container className="text-center pt-5">
              <h2 className="text-pink font-weight-bold font-avenir-bold pt-4">
                Search what they mean, not what they say
              </h2>
              <p className="lead">Keyword search fails in specialized domains like patent search</p>
              <Row className="py-4">
                <Col xs="12" md="6" className="text-center p-4 p-lg-5 d-flex align-items-center">
                  <span className="display-4">1</span>
                  <p className="pl-2 w-75 text-justify my-0 ml-3 p-border-left">
                    It&apos;s surprising how many ways you can describe the same thing. Don&apos;t guess the exact words
                    used to describe what you are looking for.
                  </p>
                </Col>
                <Col xs="12" md="6" className="text-center p-4 p-lg-5 d-flex align-items-center">
                  <span className="display-4">2</span>
                  <p className="pl-2 w-75 text-justify my-0 ml-3 p-border-left">
                    You&apos;re limited to a few keywords. Don&apos;t waste time picking the most relevant keywords that
                    appropriately represet your document.
                  </p>
                </Col>
                <Col xs="12" md="6" className="text-center p-4 p-lg-5 d-flex align-items-center">
                  <span className="display-4">3</span>
                  <p className="pl-2 w-75 text-justify my-0 ml-3 p-border-left">
                    You have specially trained people. Save your personnel&apos;s time, effort, and the drudgery of
                    doing menial tasks.
                  </p>
                </Col>
                <Col xs="12" md="6" className="text-center p-4 p-lg-5 d-flex align-items-center">
                  <span className="display-4">4</span>
                  <p className="pl-2 w-75 text-justify my-0 ml-3 p-border-left">
                    With keyword search, it&apos;s easy to miss the mark. Too narrow and you miss things. Too broad and
                    you have too many results.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
        </ScrollableAnchor>

        <div className="pb-5">
          <img src={circleLeftLarge} alt="" width="90" />
          <Container className="text-center text-primary">
            <Row className="align-items-center">
              <Col xs="12" md="6" className="pb-4 pb-md-0">
                <h2 className="font-weight-bold font-avenir-bold pt-4 display-3 text-left">
                  Don&apos;t reinvent the wheel
                </h2>
              </Col>
              <Col xs="12" md="6">
                <p className="lead text-left">
                  Starting a data science program can cost ~$250k a year. That&apos;s if you&apos;re a scrappy startup.
                  More if you&apos;re a large enterprise.
                </p>
                <p className="lead text-left mb-0 pb-2 pt-2">
                  Building and hosting machine learning models has some unique cost centers that other software
                  businesses don&apos;t:
                </p>
                <div className="d-flex flex-column align-items-start">
                  <div>
                    <GreenCheckIcon />
                    <span className="green-check-text pl-2">Compute</span>
                  </div>
                  <div>
                    <GreenCheckIcon />
                    <span className="green-check-text pl-2">Human in the loop</span>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <img src={circleRight} alt="" width="60" className="d-block ml-auto mt-n5" />
          <img src={circleLeftSmall} alt="" width="45" />
        </div>

        <div className="skip-the-product-banner bg-primary">
          <Container className="py-5 pb-2">
            <h2 className="font-weight-bold font-avenir-bold text-light mb-0">
              Skip the product development phase and focus on building the best experience for your users
            </h2>
            <Start15DayFreeTrialButton className="mt-3 mb-0 mb-sm-5" />
          </Container>
        </div>

        <div>
          <Container className="py-5">
            <Row className="mb-2 pb-2 mb-md-5 pb-md-5 align-items-center">
              <Col xs="12" md="6" className="pb-4 pb-md-0">
                <img src={doneForYouService} alt="connect easily" className="w-100 done-for-you-service-img" />
              </Col>
              <Col xs="12" md="6">
                <ScrollableAnchor id="done-for-you-service">
                  <h2 className="font-weight-bold font-avenir-bold text-secondary">Done for you service</h2>
                </ScrollableAnchor>
                <p className="lead text-left mb-0 pb-2 pt-2">
                  You upload data and Traindex gives you an API that you can integrate into your custom application or
                  workflow. Traindex engineers take care of:
                </p>
                <div className="d-flex flex-column align-items-start">
                  <div className="py-1">
                    <GreenCheckIcon />
                    <span className="green-check-text pl-2">Data cleaning and preprocessing</span>
                  </div>
                  <div className="py-1">
                    <GreenCheckIcon />
                    <span className="green-check-text pl-2">Hyper parameter tunning</span>
                  </div>
                  <div className="py-1">
                    <GreenCheckIcon />
                    <span className="green-check-text pl-2">Training</span>
                  </div>
                  <div className="py-1">
                    <GreenCheckIcon />
                    <span className="green-check-text pl-2">Hosting</span>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-3 pt-3 mt-md-5 pt-md-5 mb-2 pb-2 mb-md-5 pb-md-5 align-items-center">
              <Col xs="12" md="6">
                <h2 className="font-weight-bold font-avenir-bold text-secondary">Data Catalogs</h2>
                <p className="lead text-left mb-0 pb-2 pt-2">
                  We have data catalogs already indexed, so you get started right away. Our quickly growing list of
                  publicly available datasets:
                </p>
                <div className="d-flex flex-column align-items-start">
                  <div className="py-1">
                    <GreenCheckIcon />
                    <span className="green-check-text pl-2">Patents Corpus</span>
                  </div>
                  <div className="py-1">
                    <GreenCheckIcon />
                    <span className="green-check-text pl-2">Wikipedia</span>
                  </div>
                  <div className="py-1">
                    <GreenCheckIcon />
                    <span className="green-check-text pl-2">arXiv.org</span>
                  </div>
                </div>
              </Col>
              <Col xs="12" md="6" className="pt-4 pt-md-0">
                <img src={dataCatalogs} alt="data-catalogs" className="w-100 data-catalogs-img" />
              </Col>
            </Row>
            <BuiltInMind />
          </Container>
        </div>

        <div className="bg-secondary py-2 container-fluid"></div>

        <div className="bg-light">
          <Container className="py-5">
            <Row>
              <Col md="7">
                <h4 className="text-primary">
                  Don&apos;t have a team to do custom integration? No problem. We offer professional services at very
                  reasonable rates.
                </h4>
              </Col>
              <Col md="4" className="text-center text-md-right">
                <a href="mailto:help@traindex.io" target="_blank" rel="noreferrer noopener">
                  <Button color="secondary" className="text-white px-5 py-2 mt-3 mt-md-0 rounded-pill font-weight-bold">
                    Talk to sales
                  </Button>
                </a>
              </Col>
            </Row>
          </Container>
        </div>
      </main>
      <Footer home={true} />
    </div>
  )
}

export default LandingPage
